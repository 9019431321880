.staff {
    margin: 4rem;
}

.staff h1 {
    font-size: 2rem;
}

.leading-staff-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 1.5rem 1rem 1.5rem 1rem;
}

.staff-group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 1.5rem 1rem 1.5rem 1rem;
}

.member {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.member img {
    width: 50%;
    height: auto;
    object-fit: cover;
    border-radius: 6px;
    box-shadow: -1px 1px 62px -18px;
}

.member-text {
    width: 50%;
    text-align: start;
    font-size: 1rem;
    margin-top: 1rem;
}

.member-text h2 {
    padding-bottom: 0.5rem;
    font-size: 1.3rem;
}

@media screen and (max-width: 1200px) {
    .member img {
        width: 60%;
    }

    .member-text {
        width: 60%;
    }
}

@media screen and (max-width: 850px) {
    .staff {
        margin: 4rem;
    }

    .leading-staff-group {
        display: flex;
        flex-direction: column;
    }

    .staff-group {
        display: flex;
        flex-direction: column;
    }

    .member {
        margin-top: 1.5rem;
    }
    
    .member img {
        width: 70%;
    }

    .member-text {
        width: 70%;
    }
}

@media screen and (max-width: 600px) {
    .member img {
        width: 90%;
    }

    .member-text {
        width: 90%;
    }
}