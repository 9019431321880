.hero {
    width: 100%;
    height: 80vh;
    position: relative;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.hero-text h1 {
    font-size: 3.5rem;
    font-weight: 800;
    background-color: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: rgba(0, 0, 0, 1);
}

.hero-text p {
    font-size: 1.5rem;
    color: white;
    padding: 0.5rem 0 2rem 0;
    font-weight: 600;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(0, 0, 0, 1);
}

@media screen and (max-width: 555px) {
    .hero-text h1 {
        padding: 10px 20px;
    }

    .hero-text p {
        font-size: 1.1rem;
        padding: 0 0 2rem 0;
    }
}