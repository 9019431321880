.about-container {
    margin: 4rem 4rem;
    text-align: start;
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    gap: 30px;
}

.about-container h1 {
    padding-bottom: 1rem;
}

.about-container p {
    padding-bottom: 2rem;
}

.about-container img {
    border: 1px solid white;
    border-radius: 15px;
    box-shadow: 5px 5px 10px rgb(103, 100, 100);
}

@media screen and (max-width: 850px) {
    .about-container {
        margin: 4rem 2rem;
        display: flex;
        text-align: center;
    }

    .about-container img {
        display: none;
    }
}