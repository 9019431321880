.NavbarItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
    width: 95%;
    height: 80px;
    border-radius: 30px;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    z-index: 9999;
    background-color: white;
}

.navbar-logo {
    display: flex;
    text-decoration: none;
}

.navbar-logo h1 {
    color: black;
    font-size: 2rem;
}

.navbar-logo img {
    width: 2rem;
    margin-left: 0.5rem;
    margin-top: 0.1rem;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
}

.nav-links {
    text-decoration: none;
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.7rem 1rem;
}

.nav-links i {
    padding-right: 10px;
}

.nav-links:hover {
    background-color: aqua;
    color: white;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

.menu-icons {
    display: none;
}

@media screen and (max-width: 950px) {
    .NavbarItems {
        z-index: 99;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: white;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: -100%;
        opacity: 0;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        z-index: -1;
        border-radius: 13px;
        transition: 0.5s;
    }

    .nav-menu.active {
        left: 0;
        opacity: 1;
        z-index: -1;
        transition: 0.5s;
    }

    .nav-links {
        display: block;
        width: 100%;
        font-size: 1.2rem;
        padding: 2rem 0;
    }

    .nav-links:hover{
        background-color: rgb(5, 51, 51);
        transition: none;
    }

    .menu-icons {
        display: block;
        cursor: pointer;
    }

    .menu-icons i {
        font-size: 1.2rem;
        color: black;
    }
}
