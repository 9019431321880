.footer {
    padding: 4rem 2rem;
    background-color: black;
    color: white;
}

.top {
    display: flex;
    align-items: center;
    text-align: start;
    justify-content: space-between;
    flex-wrap: wrap;
}

.top h1 {
    margin-bottom: 0.5rem;
}

.top i {
    color: white;
    font-size: 2rem;
    margin-left: 1rem;
}

.top i:hover {
    color: aqua;
}

.bottom {
    padding-top: 4rem;
    text-align: start;
    display: flex;
    flex-wrap: wrap;
}

.bottom a {
    text-decoration: none;
    color: white;
    font-size: 1.1rem;
    margin-right: 0.6rem;
}

@media screen and (max-width: 700px) {
    .footer i {
        margin: 1rem 1rem 0 0;
    }

    .top {
        display: flex;
        flex-direction: column;
        align-items: start;
    }
}