.coming-soon {
    margin: 0rem 6rem;
    padding: 12rem 0rem;
    text-align: center;
}

.coming-soon h1 {
    font-size: 24px;
}

@media screen and (max-width:500px) {

}