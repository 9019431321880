.service {
    margin: 2rem 6rem;
    color: black;
}

.service h1 {
    font-size: 3rem;
    margin-bottom: 15px;
}

.service-card {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.s-card {
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0,0,0,0.1);
    border-radius: 7px;
    padding: 1rem .8rem;
    display: flex;
}

.s-image {
    height: 200px;
    overflow: hidden;
    border-radius: 7px;
    margin-right: 1rem;
    width: 60%;
}

.s-image img {
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
}

.s-image:hover img {
    transform: scale(1.3);
}

.service-content h4 {
    font-size: 1.3rem;
    margin-bottom: 0.3rem;
}

.service-content .online {
    margin-top: 1rem;
}

.service-content button {
    margin-top: 0.3rem;
    height: 5vh;
    width: 12vh;
    background-color: rgb(6, 60, 60);
    color: white;
    cursor: pointer;
    font-size: 100%;
}

@media screen and (max-width: 1200px) {
    .s-image {
        width: 85%;
    }
}

@media screen and (max-width: 850px) {
    .service {
        margin: 4rem 2rem;
    }

    .s-card {
        width: 90%;
        margin-bottom: 1.5rem;
        flex-direction: column;
    }

    .s-image {
        width: 100%;
        height: 250px;
    }

    .service-content {
        margin-top: 0.5rem;
    }

    .service-content button {
        height: 6vh;
        width: 14vh;
    }
}